<template>
	<div class="m-login">
		<div class="login-header wrap1200">
			<router-link to="login"><img src="@/assets/images/tiantianlogo.jpg" alt="logo" class="login-logo"></router-link>
		</div>
		<div class="login-content">
			<!-- <p @click="homepage">fda1212</p>
			<a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=1244234523&site=qq&menu=yes">
				<span  src="http://wpa.qq.com/pa?p=2::51" title="在线客服">在线11客服</span>
			</a> -->
			<div class="wrap1200 posra">
				<img src="../../assets/images/loginBanner.jpg" alt="" class="c-bannerImg">
				<div class="login-forms">
					<el-form :model="ruleForm2" ref="ruleForm2" label-width="100px" @submit.native.prevent="submitForm('ruleForm2')">
						<h5>用户登录</h5>
						<div class="login-input-item">
							<label class="iconfont icon-piliangxiugaiyewuyuan" for="loginName"></label>
							<input ref="userinput" v-model="ruleForm2.loginName" class="login-input1" name="loginName" id="loginName" type="text" placeholder="用户名/邮箱/手机号" maxlength="11">
						</div>
						<div class="login-input-item login-input-itemB">
							<label class="iconfont icon-suo1"></label>
							<input v-model="ruleForm2.pwd" class="login-input1" type="password" id="pwd" name="pwd" placeholder="密码">
						</div>
						<div class="login-errors" v-show="errorMsg">
							<span class="el-icon-warning-outline"></span> {{errorMsg}}
						</div>
						<el-row class="login-server big-checkbox">
							<el-col :span="12"><el-checkbox v-model="checked">记住账号</el-checkbox></el-col>
							<el-col :span="12" align="right"><a href="javascript:;" target="_blank">忘记密码？</a></el-col>
						</el-row>
						<div class="login-input-btn">
							<!-- :disabled="!(ruleForm2.loginName.length && ruleForm2.pwd.length)"  -->
							<a href="javascript:;" class="allWidth" :loading="btnloading"  @click="submitForm('ruleForm2')">登录</a>
							<!-- <el-button type="primary" native-type="submit" class="allWidth"></el-button> -->
						</div>
					</el-form>
				</div>
			</div>
		</div>
		<div class="login-footer wrap1200 clearfix">
			<div class="footer-left">
				<div class="con-help clearfix">
					<img src="../../assets/images/hotline-W.jpg" alt="hotline"  class="fl">
					<a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2141668434&site=qq&menu=yes">
						<img src="../../assets/images/tq-G.jpg" alt="" class="tq-help" id="contactQQ">
					</a>
				</div>
				<div class="footer-rights">
					<p><a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备16017370号</a></p>
					<p>版权所有@2019-2020 湖南中泰智本网络科技有限公司</p>
					<p>推荐浏览器：谷歌浏览器、360浏览器、QQ浏览器、搜狗浏览器、IE10及以上版本</p>
				</div>
			</div>
			<div class="footer-right">
				<ul class="clearfix">
					<li>
						<img src="../../assets/images/load-teacherApp.png" alt="" width="140">
						<p>教师端app</p>
					</li>
					<li>
						<img src="../../assets/images/load-studentApp.png" alt=""  width="140">
						<p>学生端app</p>
					</li>
					<li>
						<img src="../../assets/images/vx1.png" alt=""  width="140">
						<p>家长端公众号</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	@import "@css/pages/login.scss";
</style>
<script>
	import {setLocalStorage, ResEncrypt, setToken, getLocalStorage} from '@/utils/commonBase';
	import { aesKey,resKey, ossUrlD } from '@/config/env'
	// var encrypt=new JSEncrypt();
	export default {
		data() {
			return {
				btnloading: false,
				errorMsg: '',
				checked: false,
				ruleForm2: {
					loginName: '',
					pwd: ''
				}
			}
		},
		created () {

		},
		mounted() {
            this.$nextTick(() => {
				this.$refs.userinput.focus();
				if(getLocalStorage('loginName')) {
					this.ruleForm2.loginName = getLocalStorage('loginName');
				}
            });
        },
		methods: {
			submitForm(formName) {
				if(!this.ruleForm2.loginName) {
					this.errorMsg = '请输入账号'
					this.$refs.userinput.focus();
					return;
				}
				if(!this.ruleForm2.pwd) {
					this.errorMsg = '请输入密码';
					this.$refs.userinput.focus();
					return;
				}
				this.loginHnzt();
			},
			loginHnzt() {
				this.btnloading = true;
				var passwordRsa = ResEncrypt(this.ruleForm2.pwd);
                this.$http({
                    method: 'post',
                    url: '/api/yyb/v1/login/api/login',
                    data: {
						appSource: 2,
						appId: '3331e2a2b2a511e982d0525400c04357',
						deviceId: '',
						phoneModel: '',
						phonePlatform: 'windows',
                        loginName: this.ruleForm2.loginName,
                        loginPwd: passwordRsa
					}
                }).then(res => {
					this.btnloading = false;
					console.log('res0', res)
                    if (res.data.obj && res.data.success) {
						// 接口token
						setToken(res.data.obj);
                        // this.$setLocalStorage('ApiGateToken', res.data.obj ? JSON.stringify(res.data.obj) : '');
                        // 登录信息Cookies时长1周
						let inTimes = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
						if(this.checked) {
							setLocalStorage('loginName', this.ruleForm2.loginName);
						}
						this.$http({
							method: 'get',
							url: '/api/yyb/v1/user/api/getPuserInfo',
							params: {}
						}).then(res => {
							let cookies = {
								loginName: this.ruleForm2.loginName,
								uid: res.data.obj.uid,
								name: res.data.obj.name,
								photo: ossUrlD + res.data.obj.photo
							};
							// 设置cookie 登录名字   uid    名字  照片
							this.$ztCookies.set('USER_INFO_DEV', cookies, {expires: inTimes});
							setLocalStorage('name', res.data.obj.name);
							setLocalStorage('memberCode', res.data.obj.memberCode);
							this.$store.commit('getName', {name: res.data.obj.name});
							this.$router.push({
								path: this.$route.query.redirect ? this.$route.query.redirect : '/'
							});
						}).catch(error => {
							console.log('error1', error)
						})
                    } else {
                        this.errorMsg = res.data.msg;
                    }
                }).catch(error => {
                    console.log('error', error);
                    this.btnloading = false;
                });
            }
		}
  }
</script>
